/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .publicationcoverimagerow {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.altai-theme-content #bodyView .publicationcoverimagerow.desktop {
  display: block;
}
.altai-theme-content #bodyView .publicationcoverimagerow.desktop .publicationcoverimagecontainer {
  margin-left: 0;
  margin-right: 60px;
  text-align: center;
  width: 40%;
  float: left;
}
.altai-theme-content #bodyView .publicationcoverimagerow.desktop .publicationcoverimagecontainer .noimg {
  height: 200px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  line-height: 200px;
  font-size: 110%;
  font-weight: 500;
}
.altai-theme-content #bodyView .publicationcoverimagerow.mobile {
  display: none;
}
.altai-theme-content #bodyView .publicationcoverimagerow.mobile .publicationcoverimagecontainer {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%;
}
.altai-theme-content #bodyView .publicationcoverimagerow.mobile .publicationcoverimagecontainer .noimg {
  height: 100px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  line-height: 100px;
  font-size: 110%;
  font-weight: 500;
}
.altai-theme-content #bodyView .publicationcoverimagerow .publicationcoverimage {
  transition: 100ms ease-in-out;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}
.altai-theme-content #bodyView .publicationcoverimagerow .publicationcoverimage:hover {
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 750px) {
  .altai-theme-content #bodyView .publicationcoverimagerow.desktop {
    display: none;
  }
  .altai-theme-content #bodyView .publicationcoverimagerow.mobile {
    display: inline-block;
  }
}
